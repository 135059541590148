import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import "./App.css";

const App = () => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPercentage((prevPercentage) => Math.min(prevPercentage + 1, 100));
    }, 30);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="h-screen w-screen font-body bg-white p-3">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Marcellus&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <main className="bg-brand h-full fade">
        <div className="max-w-3xl mx-auto px-4 sm:px-12 lg:max-w-4xl lg:px-8 flex flex-col items-center justify-between text-white h-[calc(100%-5rem)] py-10 xs:py-20 wide:py-10">
          <motion.img
            initial={{ opacity: 0, translateY: -10 }}
            animate={{
              opacity: 1,
              translateY: 0,
              transition: { delay: 0.25, duration: 1 },
            }}
            src="/logo-white.png"
            alt="logo"
            className="h-10 sm:h-16 wide:h-8"
          />
          <div className="flex items-center justify-between">
            <h1
              className="text-center leading-[4.5rem] sm:leading-[inherit] text-5xl sm:text-7xl wide:text-4xl wide:mt-4 tracking-tight"
              style={
                { "--percentage": `${percentage}%` } as React.CSSProperties
              }
            >
              Coming Soon
            </h1>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-between w-full self-end gap-10 sm:gap-0">
            <motion.div
              className="flex flex-col items-center sm:items-start font-inter text-sm sm:text-base wide:text-sm wide:mt-5"
              initial={{ opacity: 0, translateX: -10 }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: { delay: 0.5, duration: 1 },
              }}
            >
              <div className="text-base sm:text-lg font-semibold">
                Mag. Ronald Frankl
              </div>
              <div className="">Rechtsanwalt / Attorney-at-Law</div>
              <a
                href="https://frankl-legal.com"
                target="_blank"
                className="font-light"
                rel="noreferrer"
              >
                frankl-legal.com
              </a>
              <a href="tel:+436766076110" className="font-light">
                +43 43 676 6076110
              </a>
              <a href="mailto:frankl@frankl-neuser.com" className="font-light">
                frankl@frankl-neuser.com
              </a>
            </motion.div>
            <motion.div
              className="flex flex-col items-center sm:items-end font-inter text-sm sm:text-base wide:text-sm wide:mt-5"
              initial={{ opacity: 0, translateX: 10 }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: { delay: 0.5, duration: 1 },
              }}
            >
              <div className="text-base sm:text-lg font-semibold">
                Mag. Valentin Neuser
              </div>
              <div className="">Rechtsanwalt / Attorney-at-Law</div>
              <a
                href="https://www.neuser-legal.com"
                target="_blank"
                className="font-light"
                rel="noreferrer"
              >
                neuser-legal.com
              </a>
              <a href="tel:+436502647896" className="font-light">
                +43 650 2647896
              </a>
              <a href="mailto:neuser@frankl-neuser.com" className="font-light">
                neuser@frankl-neuser.com
              </a>
            </motion.div>
          </div>
          <motion.div
            className="flex items-center justify-center text-white/80 text-sm sm:text-lg wide:text-sm mt-5"
            initial={{ opacity: 0, translateY: 10 }}
            animate={{
              opacity: 1,
              translateY: 0,
              transition: { delay: 0.25, duration: 1 },
            }}
          >
            Seilerstätte 17/10-11, 1010 Vienna, Austria
          </motion.div>
        </div>
        <motion.div
          className="flex items-center justify-center text-white/80 text-[9px] xs:text-xs sm:text-sm h-20 wide:mt-2"
          initial={{ opacity: 0, translateY: 15 }}
          animate={{
            opacity: 1,
            translateY: 0,
            transition: { delay: 0.5, duration: 1 },
          }}
        >
          &copy; {new Date().getFullYear()} frankl neuser LEGAL
        </motion.div>
      </main>
    </div>
  );
};

export default App;
